<!--过磅单核算台帐批量调价-->
<template>
    <div class="ProrataRates">
        <div class="ProrataRatesContent">
            <div>
                <h4>主材含税单价：</h4>
                <el-input v-model="unit_price" type="number" placeholder="请输入主材含税单价"></el-input>
            </div>
            <div>
                <h4>运费含税单价：</h4>
                <el-input v-model="transport_price" type="number" placeholder="请输入运费含税单价"></el-input>
            </div>
        </div>

        <footer>
            <button class="save" @click="save()">
                确定
            </button>
            <button class="Close" @click="Close">
                关闭
            </button>
        </footer>
    </div>
</template>

<script>
export default {
    // 发货单核算-调整单价
    name: 'prorata-rates',
    components: {},
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    data() {
        return {
            balance_numbers: [],
            unit_price: '',
            transport_price: '',
        };
    },
    computed: {},
    watch: {},
    created() {
        this.balance_numbers = this.extr.balance_numbers;

    },
    mounted() {},
    destroyed() {},
    methods: {
        // 确定
        save() {
            this.$axios
                .post('/interfaceApi/procurement/accounts/adjust_price', {
                    balance_numbers: this.balance_numbers,
                    transport_price: this.transport_price,
                    unit_price: this.unit_price,
                })
                .then(() => {
                    this.$message({
                        message: '已调整!',
                        type: 'success',
                    });
                    this.extr.callback && this.extr.callback();
                    this.$parent.hide();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 关闭
        Close() {
            this.$parent.hide();
        },
    },
};
</script>
<style lang="stylus" scoped>
.ProrataRates
    height 100%
    padding 0.14rem
    .ProrataRatesContent
        height calc(100% - 0.5rem)
        padding-top 0.3rem
        >div
            display flex
            margin-bottom 0.2rem
            h4
                white-space nowrap
                font-size 0.16rem
                line-height 0.36rem
                text-align right
                width 1.3rem
            .el-input
                width calc(100% - 1.3rem)
    footer
        height 0.4rem
        text-align center
        button
            width 1.5rem
            height 0.4rem
            font 0.18rem '微软雅黑'
            margin 0 0.15rem
            border-radius 2px
            border none
            cursor pointer
            &.save
                color #fff
                background #1577D2
            &.Close
                color #1577D2
                background #fff
                border 1px solid #1577D2
</style>
